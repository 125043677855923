import { PlotForSaleState, StatusFilterEnum } from '.';

export const initialState: PlotForSaleState = {
  List: [],
  form: {
    _id: '',
    assetNo: '',
    plotNo: '',
    location: {
      lat: 0,
      lng: 0,
      type: 'Point',
      coordinates: []
    },
    pricePerSqft: '',
    plotSize: '',
    plotType: '',
    ventureName: '',
    builderName: '',
    approvalStatus: 2,
    createdAt: '',
    updatedAt: ''
  },
  loading: false,
  buttonLoading: false,
  search: '',
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  status: StatusFilterEnum.OPEN,
  endDate: '',
  startDate: ''
};
