import { PlotFacingTypeEnum, PropertyAvailabilityStatusEnum } from 'pages/PropertyListing/types';
import { PlotStatusFilterEnum, PlotToBuyState } from '.';

export const initialState: PlotToBuyState = {
  List: [],
  loading: false,
  buttonLoading: false,
  search: '',
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  status: PlotStatusFilterEnum.ALL,
  endDate: '',
  startDate: '',
  form: {
    _id: '',
    ticketId: '',
    address: '',
    name: '',
    location: {
      type: 'Point',
      coordinates: [0, 0]
    },
    plotSize: '',
    plotType: '',
    minimumBudget: 0,
    maximumBudget: 0,
    approvals: [],
    availabilityStatus: PropertyAvailabilityStatusEnum.OPEN,
    plotFacing: PlotFacingTypeEnum.EAST,
    isActive: false,
    createdAt: '',
    updatedAt: ''
  }
};
