import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import {
  IExcelUploadFileList,
  IExcelUploadList,
  IProperty,
  PlotFacingTypeEnum,
  PropertyAvailabilityStatusEnum,
  PropertyStatusFilterEnum
} from './types';
import { initialState } from './types/initialState';

export const useClinicSlice = createSlice({
  name: 'propertyState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    setOrderDir: (state, action: PayloadAction<string>) => {
      state.orderDir = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<PropertyStatusFilterEnum>) => {
      state.status = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    updateImagesFormUploadDocumentFieldsValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      state.form.images.push({ file: action.payload.value });
    },
    doRemoveImages: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      state.form.images[action.payload.index].isDeleted = true;
    },
    clearForm: (state) => {
      state.form._id = '';
      state.form.plotType = '';
      state.form.location.lat = 0;
      state.form.location.lng = 0;
      state.form.areaOfPlot = '';
      state.form.nearbyLocation = '';
      state.form.pricePerSqft = '';
      state.form.propertyRatings = 0;
      state.form.priceAreaWise = '';
      state.form.projectSize = '';
      state.form.amenities = [];
      state.form.brochure = '';
      state.form.aboutBuilder = '';
      state.form.approvals = [];
      state.form.locationHighlights = '';
      state.form.availabilityStatus = PropertyAvailabilityStatusEnum.OPEN;
      state.form.images = [];
      state.form.reviews = [];
      state.form.faqs = [];
      state.form.createdAt = '';
      state.form.isActive = true;
      state.form.address = '';
      state.form.nearbyLocation = '';
      state.form.propertyFacing = PlotFacingTypeEnum.EAST;
    },
    clearFaqForm: (state) => {
      state.form.faqsForm.answer = '';
      state.form.faqsForm.question = '';
      state.form.faqsForm._id = '';
      state.form.faqsForm.isDeleted = false;
    },
    doAddFaq: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {
      state.form.faqs.push(state.form.faqsForm);
    },
    clearReviewForm: (state) => {
      state.form.reviewsForm.review = '';
      state.form.reviewsForm._id = '';
      state.form.reviewsForm.isDeleted = false;
    },
    doAddReview: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {
      state.form.reviews.push(state.form.reviewsForm);
    },
    doRemoveFaq: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const faq = state.form.faqs;

      if (faq[action.payload.index]?._id?.length > 0 && faq[action.payload.index]?._id !== undefined) {
        faq[action.payload.index].isDeleted = true;
      } else {
        faq.splice(action.payload.index, 1);
      }
    },
    doRemoveReview: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      const review = state.form?.reviews.filter((x) => {
        return !x.isDeleted;
      });

      if (review[action.payload.index]?._id?.length > 0 && review[action.payload.index]?._id !== undefined) {
        state.form.reviews[action.payload.index].isDeleted = true;
      } else {
        state.form.reviews.splice(action.payload.index, 1);
      }
    },
    doAdd: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},

    doUpdate: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},

    doGetList: (state) => {
      state.loading = true;
    },
    setList: (state, action: PayloadAction<Array<IProperty>>) => {
      state.List = action.payload;
    },
    setDataInForm: (state, action: PayloadAction<IProperty>) => {
      state.form = action.payload;
      //@ts-ignore
      state.form.location.lat = action.payload.location.coordinates[1];
      //@ts-ignore
      state.form.location.lng = action.payload.location.coordinates[0];
    },
    doGetById: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      state.loading = true;
    },

    doDelete: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    doChangeStatus: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    uploadImage: (
      state,
      action: PayloadAction<{
        fileName: string;
        name: string;
        callback: () => void;
      }>
    ) => {},

    setExcelPageNo: (state, action: PayloadAction<number>) => {
      state.excelPageNo = action.payload;
    },
    setExcelPageSize: (state, action: PayloadAction<number>) => {
      state.excelPageSize = action.payload;
    },
    setExcelTotalRow: (state, action: PayloadAction<number>) => {
      state.excelTotalRow = action.payload;
    },
    setExcelOrderBy: (state, action: PayloadAction<string>) => {
      state.excelOrderBy = action.payload;
    },
    setExcelOrderDir: (state, action: PayloadAction<string>) => {
      state.excelOrderDir = action.payload;
    },
    doGetExcelUploadList: (state) => {
      state.loading = true;
    },
    doGetExcelFileList: (state, action: PayloadAction<{ id: string }>) => {
      state.loading = true;
    },
    setExcelUploadList: (state, action: PayloadAction<Array<IExcelUploadList>>) => {
      state.excelList = action.payload;
    },
    setExcelFileList: (state, action: PayloadAction<Array<IExcelUploadFileList>>) => {
      state.excelFileList = action.payload;
    },
    clearDate: (state) => {
      state.startDate = null;
      state.endDate = null;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
