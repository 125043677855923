import { ConstructionLeadState, ListingDoneByTypeEnum, StatusFilterEnum } from '.';

export const initialState: ConstructionLeadState = {
  List: [],
  loading: false,
  buttonLoading: false,
  search: '',
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  status: StatusFilterEnum.ALL,
  endDate: '',
  startDate: '',
  form: {
    _id: '',
    ticketId: '',
    name: '',
    noOfFloors: 0,
    noOfBhk: 0,
    listingDoneBy: ListingDoneByTypeEnum.OWNER,
    isActive: false,
    images: [{ file: '' }],
    createdAt: '',
    updatedAt: ''
  }
};
