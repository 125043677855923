import { PlotFacingTypeEnum, PropertyAvailabilityStatusEnum, PropertyState, PropertyStatusFilterEnum } from '.';

export const initialState: PropertyState = {
  form: {
    _id: '',
    location: {
      lat: 26.963615051025762,
      lng: 75.7670243899338,
      type: 'Point',
      coordinates: []
    },
    areaOfPlot: '',
    pricePerSqft: '',
    priceAreaWise: '',
    projectSize: '',
    amenities: [],
    brochure: '',
    aboutBuilder: '',
    approvals: [],
    locationHighlights: '',
    availabilityStatus: PropertyAvailabilityStatusEnum.OPEN,
    images: [],
    reviews: [],
    faqs: [],
    plotType: '',
    reviewsForm: {
      review: '',
      _id: '',
      createdAt: '',
      updatedAt: ''
    },
    faqsForm: {
      question: '',
      answer: '',
      _id: '',
      createdAt: '',
      updatedAt: ''
    },
    createdAt: '',
    isActive: true,
    propertyFacing: PlotFacingTypeEnum.EAST,
    propertyRatings: 0,
    address: '',
    propertyNo: '',
    nearbyLocation: ''
  },
  List: [],
  loading: false,
  buttonLoading: false,
  search: '',
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  status: PropertyStatusFilterEnum.ALL,
  endDate: '',
  startDate: '',
  excelList: [],
  excelPageNo: 0,
  excelPageSize: 0,
  excelTotalRow: 0,
  excelOrderBy: '',
  excelOrderDir: '',
  excelFileList: []
};
