import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { IData, StatusFilterEnum } from './types';
import { initialState } from './types/initialState';

export const useClinicSlice = createSlice({
  name: 'constructionLeads',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    setOrderDir: (state, action: PayloadAction<string>) => {
      state.orderDir = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<StatusFilterEnum>) => {
      state.status = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },

    doAdd: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},

    doUpdate: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},

    doGetList: (state) => {},
    setList: (state, action: PayloadAction<Array<IData>>) => {
      state.List = action.payload;
    },
    doGetById: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      state.loading = true;
    },

    doDelete: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    doChangeStatus: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
