import { ILocation } from 'pages/PropertyListing/types';

export interface IPlotForSale {
  _id: string;
  assetNo: string;
  plotNo: string;
  location: ILocation;
  pricePerSqft: string;
  plotSize: string;
  plotType: string;
  ventureName: string;
  builderName: string;
  approvalStatus: PlotAdminApprovalStatusEnum;
  createdAt: string;
  updatedAt: string;
}
export enum PlotAdminApprovalStatusEnum {
  PENDING = 1,
  APPROVED = 2
}

export enum StatusFilterEnum {
  ALL = 0,
  OPEN = 1,
  CLOSE = 2,
  PENDING = 3
}
export interface PlotForSaleState {
  List: IPlotForSale[];
  form: IPlotForSale;
  loading: boolean;
  buttonLoading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  orderBy: string;
  orderDir: string;
  endDate: string | null;
  startDate: string | null;
  status: StatusFilterEnum;
}

export type InitialState = PlotForSaleState;
