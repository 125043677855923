import { IProperty, PlotFacingTypeEnum } from 'pages/PropertyListing/types';

export enum StatusFilterEnum {
  ALL = 0,
  OPEN = 1,
  CLOSE = 2,
  PENDING = 3
}
export enum OrderStatusFilterEnum {
  ALL = 'All',
  PENDING = 'Pending',
  COMPLETED = 'Completed'
}
export interface IData {
  _id: string;
  orderNo: string;
  name: string;
  plotNo: string;
  location: {
    type: 'Point';
    coordinates: [0, 0];
  };
  plotSize: string;
  address: string;
  dimensions: string;
  serviceName: string;
  status: string;
  email: string;
  amount: number;
  ventureName: string;
  plotFacing: PlotFacingTypeEnum;
  noOfPlots: number;
  plotImages: [];
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}
export interface ServiceOrderState {
  List: IData[];
  form: IProperty;
  loading: boolean;
  buttonLoading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  orderBy: string;
  orderDir: string;
  endDate: string | null;
  startDate: string | null;
  status: OrderStatusFilterEnum;
}

export type InitialState = ServiceOrderState;
